.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.sectionHeader {
  margin: 2rem 0 0.5rem 0;
}

.actionButton {
  background-color: var(--primary);
  border-radius: 3px;
  border: none;
  padding: 1rem;
  font-family: var(--content-font);
  color: var(--background);
  text-decoration: none;
  box-shadow: 0 2px 2px var(--shadow);
  transition: background-color 0.1s;
}

.actionButton:active {
  background-color: var(--primary-dark);
}

.actionButton:hover {
  color: var(--background);
}
