.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.animalDataContainer {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  width: 100%;
}

.animalListItem {
  border-bottom: 1px solid var(--shadow);
}

.animalListItem:last-child {
  border-bottom: none;
}

.iconData {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.icon {
  margin-right: 0.3rem;
}

.clickable {
  cursor: pointer;
}
