.header {
  background-color: white;
  position: fixed;
  padding: 1rem var(--content-margin);
  box-shadow: 0 2px 2px var(--shadow);
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  min-height: 2rem;
}

.logoLink {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logoLink:hover {
  color: var(--primary);
}

.logo {
  height: 1.5rem;
}

.appName {
  font-size: 1.2rem;
  font-family: var(--header-font);
  font-weight: bold;
  margin: 0 0.5rem;
}

.navigation {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.navigation > * {
  padding: 0 0.5rem;
  transition: color 0.1s;
  display: flex;
  align-items: center;
}

.expanded {
  display: none;
}

.linkIcon {
  font-size: 1.5rem;
}

@media only screen and (min-width: 600px) {
  .appName {
    font-size: 1.5rem;
  }

  .navigation > * {
    padding: 0 1rem;
  }

  .expanded {
    display: initial;
  }

  .linkIcon {
    display: none;
  }
}
