.smallCrate {
  width: 1.2rem;
  height: 1.2rem;
}

.mediumCrate {
  width: 1.6rem;
  height: 1.6rem;
}

.largeCrate {
  width: 2rem;
  height: 2rem;
}

.extraLargeCrate {
  width: 2.4rem;
  height: 2.4rem;
}

.emptyCrate,
.fullCrate {
  border: 3px solid var(--primary);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin: 0 0.4rem 0.4rem 0;
}

.fullCrate {
  background-color: var(--primary);
  color: var(--background);
}
