.animalInfo {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.animalName {
  margin-top: 0;
  margin-bottom: 0.3rem;
  font-weight: normal;
  font-size: 1.3rem;
}
