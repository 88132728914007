.crateStacks {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

.crateStack {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-top: 2rem;
}
