:root {
  --primary-light: #556b9b;
  --primary: #003c77;
  --primary-dark: #032649;
  --shadow: rgba(0, 0, 0, 0.25);
  --confirm: #10bd58;
  --invalid: #e40535;
  --warning: #b5570b;
  --background: #f0f0f0;
  --content-margin: 1rem;
  --header-font: Garamond, Georgia, Baskerville, 'Baskerville Old Face',
    'Hoefler Text', 'Times New Roman', serif;
  --content-font: Lato, Geneva, Tahoma, sans-serif;
}

@media only screen and (min-width: 850px) {
  :root {
    --content-margin: calc(50% - 400px);
  }
}

body {
  margin: 0;
  font-family: var(--content-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  color: var(--primary);
}

h2 {
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 2rem;
}

a {
  text-decoration: none;
  color: var(--primary);
}

a:hover {
  color: var(--primary-light);
}
