.cancelButton {
  border: 1px solid var(--primary);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  color: var(--primary);
  background-color: transparent;
}

.cancelButton:active {
  border-color: var(--primary-dark);
  color: var(--primary-dark);
}
